import React from 'react'
import style from './style.module.css'
import { CgProfile } from 'react-icons/cg';
import { SlCalender } from 'react-icons/sl';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

export function Props() {
    return (
        <>
            <section className={style['articalParent']}>
                <Helmet>
                    <title>Props in react. How we can use in react component?</title>
                    <meta name="google-adsense-account" content="ca-pub-9562554868569636"></meta>
                    <meta name='description' content='Props are arguments passed into react component. In simple words "Props are used to store data that can be accessed by the children of a react component. It is the concept of reusability.' />
                    <meta property="og:title" content='Props in react. How we can use in react component?' />
                    <meta property="og:description" content='Props are arguments passed into react component. In simple words "Props are used to store data that can be accessed by the children of a react component. It is the concept of reusability.' />
                    <meta property="og:url" content='https://codewithgautam.online/' />
                    <meta property="og:type" content="website" />
                    <meta property="twitter:title" content='Props in react. How we can use in react component?' />
                    <meta property="twitter:description" content='Props are arguments passed into react component. In simple words "Props are used to store data that can be accessed by the children of a react component. It is the concept of reusability.' />
                </Helmet>
                <h1>Props in react. How we can use in react component?</h1>
                <div>
                    <div>
                        <CgProfile className={style['logo']} />
                        <span className={style['logoContent']}> Gautam Singh</span>
                    </div>
                    <div>
                        <SlCalender className={style['logo']} />
                        <span className={style['logoContent']}> march 12, 2024</span>
                    </div>
                </div >
                <div>
                    <p className={style['contentPart']}>Props are arguments passed into react component. In simple words "Props are used to store data that can be accessed by the children of a react component. It is the concept of reusability.</p>
                    <p className={style['contentPart']}>In React, "props" is a commonly used abbreviation for "properties". Props are a way to pass data from a parent component to a child component in a React application. They are immutable, meaning that once they are set by the parent, they cannot be changed by the child component. Props allow you to customize and configure child components based on the needs of your application.</p>
                    <p className={style['contentPart']}>Let's dive into more detail about props:</p>
                    <ol>
                        <li>
                            <p className={style['contentPart']}><span>Passing Data: </span> Props are passed from <b>'parent'</b> to <b>'child components'</b> as attributes in <b>'JSX'</b>. For example:</p>
                            <p className={style['code']}>
                                <code>

                                    {`// ParentComponent.js`}<br />
                                    {`import ChildComponent from './ChildComponent';`}<br />
                                    import {Helmet} from 'react-helmet-async';

                                    {`function ParentComponent() `}<br />
                                    {`return (`}<br />
                                    {`<div>`}<br />
                                    {`<ChildComponent name="John" age={30} />`} <br />
                                    {`</div>`}<br />
                                    {`);`} <br />
                                    {`}`}<br />

                                </code>
                            </p>
                            <p className={style['contentPart']}>In this example, <b>'name'</b> and <b>'age'</b> under child component are <b>'props'</b> being passed to the <b>'ChildComponent'</b>.</p>
                        </li>
                        <li>
                            <p className={style['contentPart']}><span>Accessing Props: </span>In the <b>'child component'</b>, you can access <b>'props'</b> passed to it using the props object. For example:</p>
                            <p className={style['code']}>
                                <code>
                                    {`// ChildComponent.js`}<br />
                                    {`function ChildComponent(props) {`}<br />
                                    {`return (`}<br />
                                    {`<div>`}<br />
                                    {`<p>Name: {props.name}</p>`}<br />
                                    {`<p>Age: {props.age}</p>`}<br />
                                    {`</div>`}<br />
                                    {`);`}<br />
                                    {`}`}<br />
                                </code>
                            </p>
                            <p className={style['contentPart']}>Here, <b>'props.name'</b> and <b>'props.age'</b> are accessed to display the data passed from the <b>'parent component'.</b></p>
                        </li>
                        <li>
                            <p className={style['contentPart']}><span>Dynamic Data: </span>Props can hold any type of data, including strings, numbers, arrays, objects, functions, etc. This allows for dynamic rendering of components based on changing data.</p>
                        </li>
                        <li>
                            <p className={style['contentPart']}>
                                <span>Default Props: </span>
                                You can also define default values for props using the <b>'defaultProps'</b> property. This ensures that your component behaves predictably even if certain props are not provided.
                            </p>
                            <p className={style['code']}>
                                <code>
                                    {`// ChildComponent.js`}<br />
                                    {`function ChildComponent(props) {`}<br />
                                    {` return (`}<br />
                                    {`<div>`}<br />
                                    {`<p>Name: {props.name}</p>`}<br />
                                    {`<p>Age: {props.age}</p>`}<br />
                                    {` </div>`}<br />
                                    {` );`}<br />
                                    {`}`}<br /><br /><br />


                                    {`ChildComponent.defaultProps = {`}<br />
                                    {`age: 25 // Default age if not provided by the p`}<br />
                                    {`};`}<br />
                                </code>
                            </p>
                        </li>
                        <li>
                            <p className={style['contentPart']}><span>Props are Immutable: </span>Once props are set by the <b>'parent component'</b>, they cannot be modified by the <b>'child component'</b>. This helps maintain the predictability and stability of your application's data flow.</p>
                        </li>
                        <li>
                            <p className={style['contentPart']}><span>Passing Functions as Props: </span>In addition to data, you can also pass functions as props. This allows child components to communicate with parent components by invoking these functions with data passed as arguments.</p>
                            <p className={style['code']}>
                                <code>
                                    {`// ParentComponent.js`}<br />
                                    {`function ParentComponent() {`}<br />
                                    {` const handleButtonClick = () => {`}<br />
                                    {`console.log("Button clicked!");`}<br />
                                    {`};`}<br /><br />

                                    {` return (`}<br />
                                    {` <div>`}<br />
                                    {`<ChildComponent handleClick={handleButtonClick} />`}<br />
                                    {`</div>`}<br />
                                    {`);`}<br />
                                    {`}`}<br /><br />

                                    {`// ChildComponent.js`}<br />
                                    {`function ChildComponent(props) {`}<br />
                                    {`return (`}<br />
                                    {`<div>`}<br />
                                    {`<button onClick={props.handleClick}>Click me</button>`}<br />
                                    {`</div>`}<br />
                                    {`);`}<br />
                                    {`}`}<br />
                                </code>
                            </p>
                        </li>
                        <p className={style['contentPart']}>In this example, the <b>'handleButtonClick'</b> function is passed from <b>'ParentComponent'</b> to <b>'ChildComponent'</b> as the <b>'handleClick'</b> prop, which is then used as an event handler for a button click in <b>'ChildComponent'</b>.</p>
                    </ol>
                    <p className={style['contentPart']}>Overall, <b>'props'</b> play a crucial role in React development, facilitating the flow of data and behavior between components in a structured and predictable manner.</p>
                </div>
                <div className='linkArtical' >
                    <h3>Recent Articals:-</h3>
                    <Link to={"/artical/Mastering-Event-Handling-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Event Handling in React: A Comprehensive Guide.</Link>
                    <Link to={"/artical/Mastering-List-Rendering-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering List Rendering in React: A Comprehensive Guide.</Link> 
                    <Link to={"/artical/Mastering-Conditional-Rendering-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Conditional Rendering in React: A Comprehensive Guide.</Link> 
                    <Link to={"/artical/Mastering-Styling-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Styling in React: A Comprehensive Guide.</Link>
                    <Link to={"/artical/What is jsx what is usecase of react"} className='sublink' reloadDocument>What is JSX and its usecase in react.</Link>
                    <Link to={"/artical/What is component How we can use this"} className='sublink' reloadDocument>What is component? How we can use this.</Link>
                    <Link to={"/artical/What Is React why it is usefull"} className='sublink' reloadDocument>What is react? why it is usefull.</Link>
                </div>
            </section>
        </>
    )
}

