import React from 'react'
import style from './style.module.css'
import { Helmet } from 'react-helmet-async';
import img1 from './articalImg/componentexample1.jpg'
import img2 from './articalImg/componentexample2.jpg'
import img3 from './articalImg/componentexample3.jpg'
import img4 from './articalImg/componentexample4.jpg'
import img5 from './articalImg/componentexample5.jpg'
import img6 from './articalImg/componentexample6.jpg'

import { CgProfile } from 'react-icons/cg';
import { SlCalender } from 'react-icons/sl';
import { Link } from 'react-router-dom'
export function WhatISComponent() {
   
    return (
        <section className={style['articalParent']}>
            <Helmet>
                <title>What is component? How we can use this</title>
                <meta name="google-adsense-account" content="ca-pub-9562554868569636"></meta>
                <meta name='description' content='In react we create a component, component are nothing but simple javascript function that return html like code. This html code is called as jsx (javascript XML). Imagine react component (Which is essentially javascript function) as custom html tag' />
                <meta property="og:title" content='What is component? How we can use this' />
                <meta property="og:description" content='In react we create a component, component are nothing but simple javascript function that return html like code. This html code is called as jsx (javascript XML). Imagine react component (Which is essentially javascript function) as custom html tag.' />
                <meta property="og:image" content={img1} />
                <meta property="og:image" content={img2} />
                <meta property="og:image" content={img3} />
                <meta property="og:image" content={img4} />
                <meta property="og:image" content={img5} />
                <meta property="og:image" content={img6} />
                <meta property="og:url" content='https://codewithgautam.online/' />
                <meta property="og:type" content="website" />
                <meta property="twitter:title" content='What is component? How we can use this' />
                <meta property="twitter:description" content='In react we create a component, component are nothing but simple javascript function that return html like code. This html code is called as jsx (javascript XML). Imagine react component (Which is essentially javascript function) as custom html tag.' />
                <meta property="twitter:image" content={img1} />
                <meta property="twitter:image" content={img2} />
                <meta property="twitter:image" content={img3} />
                <meta property="twitter:image" content={img4} />
                <meta property="twitter:image" content={img5} />
                <meta property="twitter:image" content={img6} />
            </Helmet>
            <h1>What is component? How we can use this</h1>
            <div>
                <div>
                    <CgProfile className={style['logo']} />
                    <span className={style['logoContent']}> Gautam Singh</span>
                </div>
                <div>
                    <SlCalender className={style['logo']} />
                    <span className={style['logoContent']}> march 6, 2024</span>
                </div>
            </div>
            <div>
                <p className={style['contentPart']}>“React app are made out of a component. A component is a piece of the UI (user interface) that has its own logic and appearance. A component can be small as button, or large as its entire page.” In react we create a component, component are nothing but simple javascript function that return html like code. This html code is called as jsx (javascript XML)
                    Imagine react component (Which is essentially javascript function) as custom html tag
                </p>
                <img src={img1} alt='img1' width="85%" height="auto" className = {style['compoImg']}/>
                <div className = {style['componentContent']}>
                <p className={style['contentPart']}>The above img will show -<br />
                    <span style={{ fontStyle: "bold" }}>{`Const isOldMan = person.age>30?"old guy":"young guy";`}</span><br />
                    this is called as logic and <br />
                    <span style={{ fontStyle: "bold" }}>{`<h2>{person.name} is a {isOldMan}</h2>;`}</span><br />
                    this is called as appearance.
                </p>
                <img src={img2} alt='img2' className = {style['compoImg']}/>
                <p className={style['contentPart']}>React follow the concept of the composition meant start from the smallest atomic piece of UI and then go large that is increasing order</p>
                <p className={style['contentPart']}><span style={{fontStyle:"bold"}}>for example:</span><br /> This is a heading component.<br />this is an Anchor component.<br />this is a Button component.<br /> All the three component should resides under the div tag
                </p>
                <img src={img3} alt='img3' className = {style['compoImg']} /><br/>
                <img src={img4} alt='img4' className = {style['compoImg']}/><br/>
                <img src={img5} alt='img5' className = {style['compoImg']}/><br/>
                <img src={img6} alt='img6' className = {style['compoImg']}/><br/>
                </div>
            </div>
            <div className={style['contentPart']}>
            A React component is the fundamental building block of a React application. It's a modular, reusable piece of code responsible for rendering a part of the user interface (UI). Components encapsulate both the visual elements and the behavior of a portion of the UI, making it easier to manage and maintain complex applications.
            </div>
            <div className='linkArtical' >
                <h3>Recent Articals:-</h3>
                <Link to={"/artical/Mastering-Event-Handling-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Event Handling in React: A Comprehensive Guide.</Link>
                <Link to={"/artical/Mastering-List-Rendering-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering List Rendering in React: A Comprehensive Guide.</Link>
                <Link to={"/artical/Mastering-Conditional-Rendering-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Conditional Rendering in React: A Comprehensive Guide.</Link> 
                <Link to={"/artical/Mastering-Styling-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Styling in React: A Comprehensive Guide.</Link>
                <Link to={"/artical/Props in react How we can use in react component"} className= 'sublink' reloadDocument>Props in react. How we can use in react component?</Link>
                <Link to={"/artical/What is jsx what is usecase of react"} className= 'sublink'  reloadDocument>What is JSX and its usecase in react.</Link>
                <Link to={"/artical/What Is React why it is usefull"} className = 'sublink' reloadDocument>What is react? why it is usefull.</Link>
            </div>
        </section>
    )
}

