import React from 'react'
import { Helmet } from 'react-helmet-async';
import style from './style.module.css'
import { CgProfile } from 'react-icons/cg';
import { SlCalender } from 'react-icons/sl';
import jsximg from './articalImg/jsxExample.jpg';
import { Link } from 'react-router-dom';

export function WhatIsJsx() {
    return (
        <>
            <Helmet>
                <title>JSX and its usecases</title>
                <meta name="google-adsense-account" content="ca-pub-9562554868569636"></meta>
                <meta name='description' content='JSX is a syntax extension for JavaScript that was introduced by React. It allows you to write HTML-like code directly within your JavaScript files. JSX is compile into Js object via method called createElement this method is supplied by react library.' />
                <meta property="og:title" content='JSX and its usecases' />
                <meta property="og:description" content='JSX is a syntax extension for JavaScript that was introduced by React. It allows you to write HTML-like code directly within your JavaScript files. JSX is compile into Js object via method called createElement this method is supplied by react library.' />
                <meta property="og:image" content={jsximg} />
                <meta property="og:url" content='https://codewithgautam.online/' />
                <meta property="og:type" content="website" />
                <meta property="twitter:title" content='JSX and its usecases' />
                <meta property="twitter:description" content='JSX is a syntax extension for JavaScript that was introduced by React. It allows you to write HTML-like code directly within your JavaScript files. JSX is compile into Js object via method called createElement this method is supplied by react library.' />
                <meta property="twitter:image" content={jsximg} />
            </Helmet>
            <section className={style['articalParent']}>
                <h1>What is jsx? what is the usecase of react</h1>
                <div>
                    <div>
                        <CgProfile className={style['logo']} />
                        <span className={style['logoContent']}> Gautam Singh</span>
                    </div>
                    <div>
                        <SlCalender className={style['logo']} />
                        <span className={style['logoContent']}> march 9, 2024</span>
                    </div>
                </div >
                <p className={style['contentPart']}>When you first start diving into React, you'll quickly encounter JSX. JSX stands for JavaScript XML, and it's an extension of JavaScript that allows you to write HTML-like syntax within your JavaScript code. While it may seem unusual at first, JSX is a powerful tool that simplifies the process of creating React components.</p>
                <div>
                    <h2 className={style['subheading']}>What is JSX?</h2>
                    <p className={style['contentPart']}>JSX is a syntax extension for JavaScript that was introduced by React. It allows you to write HTML-like code directly within your JavaScript files. JSX is compile into Js object via method called createElement this method is supplied by react library.</p>
                    <span className={style['contentPart']}>Example:</span>
                    <p className={style['examplejsx']}>
                        <span>{`<h1>Something Write</h1>`}</span>
                        <span>this work look like:</span>
                        <span>{`React.createElement(“h1”, {}, “something write”)`}</span>
                    </p>
                    <p className={style['contentPart']}>means you can write  {` " <h1>Something Write</h1> " `} or {` "React.createElement(“h1”, {}, “something write” )`} both work same. Under React.createElement the first argument will show what tag you define and second argument will show what style you define and the last content will show the content of the tag. </p>
                    <p className={style['examplejsx']}>
                        <span>{`const element = <h1>Hello, world!</h1>`}</span>
                    </p>
                    <p className={style['contentPart']}>In this example, {`<h1>Hello, world!</h1>`} looks like HTML, but it's actually JSX. JSX gets transformed into regular JavaScript functions by tools like Babel before being rendered by the browser.</p>
                    <ul className={style['contentPart']}>
                        <li>React app is nothing but bunch of nested createElement method calls internally.</li>
                        <li>Create element method return javaScript Object.</li>
                        <li>ReacompoImgct app is nothing but giant js object which is holding multiple js object visually we can call it as a tree hierarchy getting formed.</li>
                    </ul>
                    <div>
                        <h2 className={style['subheading']}>Why Use JSX?</h2>
                        <p className={style['contentPart']}>
                            JSX has several advantages:</p>
                        <p className={style['contentPart']}><span>Readability: </span>JSX makes your code more readable by allowing you to write HTML-like syntax. This can be especially helpful when working with UI components, as it closely resembles the final output.</p>
                        <p className={style['contentPart']}><span>Easy to Write: </span>Writing JSX feels natural for web developers who are familiar with HTML. It reduces the cognitive overhead of switching between JavaScript and HTML syntax.</p>
                        <p className={style['contentPart']}><span>Expressive: </span>JSX allows you to embed JavaScript expressions within curly braces { }. This makes it easy to dynamically generate content based on data or application state.</p>
                        <p className={style['contentPart']}><span>Integration with JavaScript: </span>Since JSX is just syntactic sugar for function calls, you can use JavaScript expressions and logic directly within your JSX code.</p>
                        <h2 className={style['subheading']}>JSX Elements</h2>
                        <p className={style['contentPart']}>In JSX, HTML tags become React elements. You can use any valid HTML tag, as well as custom React components. JSX elements can also include attributes and children</p>
                        <p className={style['examplejsx']}>

                            <span>{`const element = (`}</span>
                            <span>{`<div className="container">`}</span>
                            <span>{`<h1>Hello, world!</h1>`}</span>
                            <span>{`<p>Welcome to my React app.</p>`}</span>
                            <span>{`</div>`}</span>
                            <span>{`);`}</span>

                        </p>
                        <p className={style['contentPart']}>In this example, className is used instead of class to avoid conflicts with the JavaScript class keyword.</p>
                        <h2 className={style['subheading']}>JSX and JavaScript Expressions</h2>
                        <p className={style['contentPart']}>JSX allows you to embed JavaScript expressions within curly braces { }. This makes it easy to dynamically generate content:</p>
                        <p className={style['examplejsx']}>
                            <span>const name = "Alice";</span>
                            <span>{`const element = <h1>Hello, {name}!</h1>;`}</span>
                        </p>
                        <p className={style['contentPart']}>You can use any valid JavaScript expression inside curly braces, including function calls, ternary operators, and arithmetic operations.</p>
                    <h2 className={style['subheading']}>JSX in Practice</h2>
                    <p className={style['contentPart']}>Here's a simple example of how JSX is used in a React component:</p>
                     <p className={style['compoImg']}>
                     <img src= {jsximg} alt='jsximg' className={style['compoImg']}/>
                     </p>
                     <p className={style['contentPart']}>In this example, we define a functional component called 'Greeting' that takes a 'name' prop and renders a greeting message using JSX.</p>
                   <h2 className={style['subheading']}>Conclusion</h2>
                   <p className={style['contentPart']}>JSX is a powerful tool that simplifies the process of creating React components. By allowing you to write HTML-like syntax within your JavaScript code, JSX improves readability, expressiveness, and developer productivity. While it may seem strange at first, JSX quickly becomes second nature for React developers.</p>
                    <p className={style['contentPart']}>Understanding JSX is essential for anyone learning React, as it's the primary way of defining UI components in React applications. With practice, you'll become proficient in using JSX to build dynamic and interactive user interfaces.</p>
                    </div>

                </div>

                <div className='linkArtical' >
                <h3>Recent Articals:-</h3>
                <Link to={"/artical/Mastering-Event-Handling-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Event Handling in React: A Comprehensive Guide.</Link>
                <Link to={"/artical/Mastering-List-Rendering-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering List Rendering in React: A Comprehensive Guide.</Link>
                <Link to={"/artical/Mastering-Conditional-Rendering-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Conditional Rendering in React: A Comprehensive Guide.</Link> 
                <Link to={"/artical/Mastering-Styling-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Styling in React: A Comprehensive Guide.</Link>
                <Link to={"/artical/Props in react How we can use in react component"} className= 'sublink' reloadDocument>Props in react. How we can use in react component?</Link>
                <Link to={"/artical/What is component How we can use this"} className= 'sublink'  reloadDocument>What is component? How we can use this.</Link>
                <Link to={"/artical/What Is React why it is usefull"} className = 'sublink' reloadDocument>What is react? why it is usefull.</Link>
            </div>
            </section>
            </>
    )
}
