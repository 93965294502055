import React from 'react'
import whoWeAre from '../../image/AboutMe.webp'
function About() {
    return (
        <section className='about'>
           <img src={whoWeAre} alt='whoWeAre' className='img' />
           <div className='contentMe'>
           <h1 >Who we are</h1>
           <p>We are passionate about empowering developers of all levels to learn and master React. Our mission is to provide comprehensive and accessible resources that enable individuals to build robust, modern web applications with confidence.</p>
           <p>We believe that technology should be inclusive and accessible to everyone. With React's growing importance in the web development landscape, we are committed to demystifying its concepts and making learning enjoyable and rewarding for our community.</p>
           </div>
        </section>
    )
}

export default About
