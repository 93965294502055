import React from 'react'
import contentImg from "../../image/contentImg.webp"
import { Link } from 'react-router-dom'

function Herobanner() {
  return (
    <section className='imgParent'>
        <div className= 'link'>
            <h1 >Letest Articals:- </h1>
        <Link to={"./artical/Mastering-Event-Handling-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Event Handling in React: A Comprehensive Guide.</Link> 
        <Link to={"./artical/Mastering-List-Rendering-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering List Rendering in React: A Comprehensive Guide.</Link> 
        <Link to={"./artical/Mastering-Conditional-Rendering-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Conditional Rendering in React: A Comprehensive Guide.</Link> 
        <Link to={"./artical/Mastering-Styling-in-React:-A-Comprehensive-Guide"} className= 'sublink' reloadDocument>Mastering Styling in React: A Comprehensive Guide.</Link> 
        <Link to={"./artical/Props in react How we can use in react component"} className= 'sublink' reloadDocument>Props in react. How we can use in react component?</Link>
        <Link to={"./artical/What is jsx what is usecase of react"} className= 'sublink'  reloadDocument>What is JSX and its usecase in react.</Link>
        <Link to={"./artical/What is component How we can use this"} className= 'sublink'  reloadDocument>What is component? How we can use this.</Link>
        <Link to={"./artical/What Is React why it is usefull"} className= 'sublink' reloadDocument>What is react? why it is usefull.</Link>
        
        </div>
      <img  src = {contentImg}   alt='heroImg' className='img'></img>
    </section>
  )
}

export default Herobanner
