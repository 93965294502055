import React from 'react'
import { Link } from 'react-router-dom'
export function Page404() {
  return (
    <section className='page404'>
    <h1>Oops! Looks like you're lost...</h1>
    <p>
        "Don't worry, you're not the first adventurer to stray off the path. It happens to the best of us! While you're here, why not take a moment to explore some of the wonders of our site. Please go to my <Link to={"/"}>homepage</Link> "codewithgautam.online" and check out some content" 
      </p>
      <p>
      "While you're here, why not take a deep breath and enjoy the serenity of this moment? Life's an adventure, and sometimes the detours lead to the most beautiful discoveries"
      </p>

      <p>
        "If you believe this is a technical glitch on our part, please feel free to reach out to our team. We're committed to making your browsing experience as smooth as possible"
      </p>
    </section>
  )
}

