import React from 'react'
import { Helmet } from 'react-helmet-async'

export function About() {
  return (
    <section>
      <Helmet>
        <title>AboutUs</title>
        <meta name="google-adsense-account" content="ca-pub-9562554868569636" />
        <meta name='description' content='codewithgautam.online stands as a premier online destination committed to empowering aspiring web developers with comprehensive tutorials and resources. Whether you are taking your first steps in react, JavaScript, or other related technologies, or you are a seasoned coder looking to sharpen your skills, codewithgautam.online has everything you need to succeed.' />
        <meta property="og:title" content='AboutUs' />
        <meta property="og:description" content='codewithgautam.online stands as a premier online destination committed to empowering aspiring web developers with comprehensive tutorials and resources. Whether you are taking your first steps in react, JavaScript, or other related technologies, or you are a seasoned coder looking to sharpen your skills, codewithgautam.online has everything you need to succeed.' />
        <meta property="og:url" content='https://codewithgautam.online/' />
        <meta property="og:type" content="website" />
        <meta property="twitter:title" content='AboutUs' />
        <meta property="twitter:description" content='codewithgautam.online stands as a premier online destination committed to empowering aspiring web developers with comprehensive tutorials and resources. Whether you are taking your first steps in react, JavaScript, or other related technologies, or you are a seasoned coder looking to sharpen your skills, codewithgautam.online has everything you need to succeed.' />
      </Helmet>
      <div className='aboutParent'>
        <h1 className='aboutus'>AboutUs</h1>
        <p>Welcome to our Tech Blog codewithgautam.online, your go-to source for the latest insights, trends, and innovations in the ever-evolving world of technology. At our blog codewithgautam.online, we're dedicated to delivering high-quality content that informs, educates, and inspires tech enthusiasts and professionals alike. Our team of experts curates in-depth articles, tutorials, reviews, and industry analyses to keep you ahead of the curve. Whether you're a seasoned developer, a curious gadget enthusiast, or a business leader navigating the digital landscape, our blog is your trusted companion. Join us as we explore the fascinating realm of technology and uncover the possibilities that lie ahead.</p>
        <p>codewithgautam.online stands as a premier online destination committed to empowering aspiring web developers with comprehensive tutorials and resources. Whether you're taking your first steps in react, JavaScript, or other related technologies, or you're a seasoned coder looking to sharpen your skills, codewithgautam.online has everything you need to succeed.</p>
      </div>

      <div className='aboutSubContent'>
        <h2 className='aboutus'>What we are think</h2>

        <div className='subContent1about'>
          <div className='paraAboutContent'>
            <h3>Embracing the Power of First Principles</h3>
            <p>Before embarking on any endeavor, it's imperative to grasp the fundamental purpose behind it. Our focus lies not in emulating others, but in comprehending the underlying motivation guiding our actions.</p>
          </div>

          <div className='paraAboutContent'>
            <h3>Humble Mindset</h3>
            <p>We uphold a culture where meritocracy reigns supreme, enabling the most innovative concepts to emerge irrespective of their origin. Through attentive listening and soliciting diverse perspectives, we foster an environment conducive to harnessing collective wisdom.</p>
          </div>
        </div>

        <div className='subContent1about'>
          <div className='paraAboutContent'>
            <h3>Compassionate Understanding</h3>
            <p>Centering on the viewpoints of others serves as the cornerstone of our endeavors and their underlying purpose. By empathetically stepping into the shoes of customers, prospects, or colleagues, we craft experiences that exceed expectations.</p>
          </div>

          <div className='paraAboutContent'>
            <h3>A Manifesto for Continuous Growth</h3>
            <p>Curiosity propels our quest for continuous learning and development, igniting a relentless hunger for knowledge within both ourselves and our organization.</p>
          </div>
        </div>

        <div className='subContent1about'>
          <div className='paraAboutContent'>
            <h3>Nurturing Curiosity and Growth</h3>
            <p id='unitAboutContent'>Amidst a multitude of pressing challenges, we adhere to a focused approach, addressing each issue methodically, one step at a time. Recognizing the potential overwhelm of large-scale problems, we adeptly deconstruct them into manageable components, maximizing our ability to effect meaningful change with precision and impact.</p>
          </div>
        </div>

      </div>
    </section>
  )
}
