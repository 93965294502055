import React from 'react'
import contactImg from '../../image/contactUs.gif'
import { IoLocationOutline } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
function Contactus() {
  return (
    <section className='contactParent'>
    <div className='contactContent'>
        <h1>Contact us</h1>
        <p>Do you have any questions? Please do not hesitate to contact us directly.</p>
        <p>
        <IoLocationOutline style={{fontSize:"1.6rem"}}/>
        <span> Bhopal(M.P.), India</span>
        </p>
        <p>
        <IoCallSharp style={{fontSize:"1.6rem"}}/>
        <span> +919452973962</span>
        </p>
        <p>
        <MdOutlineEmail style={{fontSize:"1.6rem"}}/>
        <span> reacthelp@gmail.com</span>
        </p>
       
    </div>
      <img src={contactImg} alt='contactImg'/>
    </section>
  )
}

export default Contactus;
