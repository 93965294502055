import React from 'react'
import { Helmet } from 'react-helmet-async'
export function Contact() {
  return (
    <div>
      <Helmet>
      <meta name="google-adsense-account" content="ca-pub-9562554868569636" />
      </Helmet>
      contact
    </div>
  )
}

