import React from 'react'
import logo from "../image/react-icons.svg"
import { Link, NavLink } from 'react-router-dom'
function Navbar() {
  return (
    <header className='header'>
    <nav className="navbar navbar-expand-lg" data-bs-theme="dark">
      <div className="container-fluid">

        <div className="navbar-brand">
          <Link to={'/'} className='navLogo' reloadDocument>
            <img src={logo} alt="Bootstrap" width="50" height="34" />
            <span className="iconText">codewithgautam</span>
          </Link>
        </div>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse navItemParent" id="navbarNavAltMarkup">
          <div className='navItem'>
            <NavLink to={'/'} className='navSubItem' reloadDocument>Home</NavLink>
            <NavLink to={'/about'}  className='navSubItem' reloadDocument>About</NavLink>
            <NavLink to={'/contactUs'}  className='navSubItem' reloadDocument>ContactUs</NavLink>
            </div>
        </div>

      </div>

    </nav>
    </header>
  )
}

export default Navbar
