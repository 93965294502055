import React from 'react'
import Herobanner from './heroBanner/Herobanner'
import About from './about/About'
import Contactus from './contact/Contactus'
import { Helmet } from 'react-helmet-async';

function Home() {
  return (
    <>
    <Helmet>
                <title>homepage</title>
                <meta name="google-adsense-account" content="ca-pub-9562554868569636"></meta>
                <meta name='description' content='This is the homepage of my website'/>
                <meta property="og:title" content='homepage' />
                <meta property="og:description" content='This is the homepage of my website.' />
                <meta property="og:url" content='https://codewithgautam.online/' />
                <meta property="og:type" content="website" />
                <meta property="twitter:title" content='homepage.' />
                <meta property="twitter:description" content='homepage.' />
            </Helmet>
    <Herobanner/>
    <About/>
    <Contactus/>
    </>
  )
}

export default Home
