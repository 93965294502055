import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <p>&copy; {currentYear}<Link to={'./'} className='navLogo' reloadDocument> codewithgautam.online</Link>. All rights reserved.</p>
    </footer>
  );
};

export default Footer;