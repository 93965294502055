import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './component/navbar/Navbar';
import Footer from './component/footer/Footer';
import Home from './component/homepage/Home';
import { WhatIsReact } from './component/articals/WhatIsReact';
import { WhatISComponent } from './component/articals/WhatISComponent';
import { WhatIsJsx } from './component/articals/WhatIsJsx';
import { Props } from './component/articals/Props';
import { StyleinReact } from './component/articals/StyleinReact';
import { ConditionRendering } from './component/articals/ConditionRendering';
import { ListRendering } from './component/articals/ListRendering';
import { EventHandeling } from './component/articals/EventHandeling';
import { Page404 } from './pages/Page404';
import { About } from './pages/About';
import { Contact } from './pages/Contact';

function App() {
  const helmetContext = {};
  return (
      <HelmetProvider context={helmetContext}>

      <BrowserRouter>
          <Navbar />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contactUs" element={<Contact />} />

            {/* Articals */}

            <Route path='/artical/Mastering-Event-Handling-in-React:-A-Comprehensive-Guide' element={<EventHandeling/>} />
            <Route path='/artical/Mastering-List-Rendering-in-React:-A-Comprehensive-Guide' element={<ListRendering/>} />
            <Route path='/artical/Mastering-Conditional-Rendering-in-React:-A-Comprehensive-Guide' element={<ConditionRendering/>} />
            <Route path='/artical/Mastering-Styling-in-React:-A-Comprehensive-Guide' element={<StyleinReact/>} />
            <Route path='/artical/What Is React why it is usefull' element={<WhatIsReact />} />
            <Route path='/artical/What is component How we can use this' element={<WhatISComponent />} />
            <Route path='/artical/What is jsx what is usecase of react' element= {<WhatIsJsx/>} />
            <Route path='/artical/Props in react How we can use in react component' element= {<Props/>} />

            {/* End of Articals */}
            
            <Route path="*" element={<Page404 />} />
          </Routes>

          <Footer />
      </BrowserRouter>

      </HelmetProvider>
  );
}

export default App;
